import * as Trix from 'trix';

Trix.config.lang = {
  bold: 'Fett',
  bullets: 'Liste',
  byte:  'Byte',
  bytes: 'Bytes',
  captionPlaceholder: 'Bildunterschrift hinzufügen…',
  code: 'Code',
  heading1: 'Titel',
  indent: 'Einzug vergrössern',
  italic: 'Kursiv',
  link: 'Link',
  numbers: 'Aufzählung',
  outdent: 'Einzug verkleinern',
  quote: 'Zitat',
  redo: 'Wiederherstellen',
  remove: 'Entfernen',
  strike: 'Durchgestrichen',
  undo: 'Rückgängig',
  unlink: 'Link entfernen',
  url: 'URL',
  urlPlaceholder: 'URL eingeben…',
  GB: 'GB',
  KB: 'KB',
  MB: 'MB',
  PB: 'PB',
  TB: 'TB',
};